import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import View from "../View";
import '../css/Navbar.css';
import logo from "../img/logo.png";

const drawerWidth = 240;
const navItems = ['/firmar', '/verificar'];

function Navbar(props) {
    const location = useLocation();
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [hoveredItem, setHoveredItem] = React.useState(null);
    const [selectedItem, setSelectedItem] = React.useState(() => {
        const storedSelectedItem = localStorage.getItem('selectedItem');
        if (storedSelectedItem && navItems.includes(storedSelectedItem)) {
            return storedSelectedItem;
        } else {
            return navItems[0];
        }
    });

    useEffect(() => {
        setSelectedItem(location.pathname);
    }, [location]);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const handleMouseEnter = (item) => {
        if (selectedItem !== item) {
            setHoveredItem(item);
        }
    };

    const handleNavItemClick = (item) => {
        localStorage.setItem('selectedItem', item);
        setSelectedItem(item);
        handleDrawerToggle();
    };

    const handleMouseLeave = () => {
        setHoveredItem(null);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center', width: drawerWidth }}>
            <Typography variant="h6" sx={{ my: 2 }}>
                <img src={logo} alt="Poder Judicial" className='imgNavbarMobile' />
            </Typography>
            <Divider />
            <List>
                {navItems.map((item, index) => (
                    <ListItem key={item} disablePadding>
                        <ListItemButton
                            sx={{
                                textAlign: 'center',
                                maxWidth: '60%',
                                margin: 'auto',
                                borderRadius: '20px',
                                backgroundColor: selectedItem === item ? '#1976d2' : (hoveredItem === item ? '#1565c0' : 'transparent'),
                            }}
                            component={Link}
                            to={item}
                            onClick={() => handleNavItemClick(item)}
                            onMouseEnter={() => handleMouseEnter(item)}
                            onMouseLeave={handleMouseLeave}
                        >
                            <ListItemText primary={capitalizeFirstLetter(item.replace('/', ''))} sx={{ fontSize: '1rem', color: selectedItem === item || hoveredItem === item ? '#fff' : '#555' }} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CssBaseline />
            <AppBar component="nav" sx={{ backgroundColor: "white", color: "#fff", boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' }}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, color: "#1976d2", margin: "auto", display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{
                            flexGrow: 1,
                            display: 'flex',
                            justifyContent: { xs: 'center', sm: 'flex-start' },
                            alignItems: 'center',
                        }}
                    >
                        <img src={logo} alt="Poder Judicial" className='imgNavbarMobile' />
                    </Typography>
                    <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
                        {navItems.map((item, index) => (
                            <Button
                                className='buttonNavbar'
                                key={item}
                                sx={{
                                    color: selectedItem === item || hoveredItem === item ? '#fff' : '#555',
                                    border: `2px solid ${selectedItem === item ? '#1976d2' : 'transparent'}`,
                                    backgroundColor: selectedItem === item ? '#1976d2' : 'transparent',
                                    ...(selectedItem === item && {
                                        '&:hover': {
                                            backgroundColor: '#1565c0',
                                            border: `2px solid #1976d2`,
                                        }
                                    }),
                                    ...(selectedItem !== item && {
                                        '&:hover': {
                                            backgroundColor: 'transparent',
                                            color: '#1565c0',
                                        }
                                    })
                                }}
                                component={Link}
                                to={item}
                                onClick={() => handleNavItemClick(item)}
                                onMouseEnter={() => handleMouseEnter(item)}
                                onMouseLeave={handleMouseLeave}
                            >
                                {capitalizeFirstLetter(item.replace('/', ''))}
                            </Button>

                        ))}
                    </Box>
                </Toolbar>
            </AppBar>
            <nav>
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </nav>
            <Box component="main" sx={{ p: 3 }}>
                <Toolbar />
                <View />
            </Box>
        </Box>
    );
}

Navbar.propTypes = {
    window: PropTypes.func,
};

export default Navbar;
