function abrirFirmador(signer_filter, signer_url_api_call, stamp, messageCallback, errorCallback, errorPopupCallback) {
    var websocketFirmador;

    websocketFirmador = new WebSocket("ws://localhost:8025/websockets/firmador");
    var params = "filtro:" + signer_filter;
    var url_fm = "url_docs:" + signer_url_api_call;
    var stampParam = "img_estampa:" + stamp;
    
    websocketFirmador.onopen = function() {
        console.log("Conexion abierta");
        websocketFirmador.send(params);
        websocketFirmador.send(url_fm);
        websocketFirmador.send(stampParam);
    };

    websocketFirmador.onmessage = function(message) {
        console.log("Mensaje recibido: " + message.data);
        messageCallback(message.data);
    };

    websocketFirmador.onclose = function(event) {
        console.log("Conexion cerrada");
        messageCallback("Conexión cerrada");
    };

    websocketFirmador.onerror = function(error) {
        console.error("Error al abrir la conexión WebSocket:", error);
        if (errorCallback) {
            errorCallback(error);
        }
        if (errorPopupCallback) {
            errorPopupCallback();
        }
    };
}

export default abrirFirmador;
