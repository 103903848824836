import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import React from 'react';

const CustomSnackbar = ({ open, message, severity, onClose }) => {
    return (
        <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={onClose}
        >
            <MuiAlert
                elevation={6}
                variant="filled"
                onClose={onClose}
                severity={severity}
                style={{ fontFamily: "DM Sans !important" }}
            >
                {message}
            </MuiAlert>
        </Snackbar>
    );
}

export default CustomSnackbar;
