import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import React, { useEffect, useState } from 'react';

const PdfViewer = ({ pdfBase64 }) => {
    const [pdfUrl, setPdfUrl] = useState(null);

    useEffect(() => {
        if (pdfBase64) {
            setPdfUrl(pdfBase64); 
        }
    }, [pdfBase64]);

    return (
        <div style={{ height: '750px' }}>
            {pdfUrl && (
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                    <Viewer fileUrl={pdfUrl} />
                </Worker>
            )}
        </div>
    );
};

export default PdfViewer;
