import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Popper, Typography } from '@mui/material';
import React from 'react';


const CustomErrorSignPopper = ({ open, onClosePopper }) => {
    return (
        <Popper
            open={open}
            placement="bottom"
            transition
            className='popperErrorUpload'
        >
            <div>
                <IconButton
                    onClick={onClosePopper}
                    className='iconButtonPopperErrorUpload'
                >
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6" gutterBottom className='h6UploadPopperError'>
                    Error al abrir el firmador
                </Typography>
                <Typography variant="body1" style={{ fontFamily: "DM Sans !important" }} gutterBottom>
                    Por favor, inténtalo de nuevo más tarde.
                </Typography>
                <Typography variant="body2" className="bodyUploadPopperError">
                    Si aún no tienes el firmador instalado, puedes descargarlo desde <a href="https://signer-update.pjm.gob.ar/">este enlace</a>.
                </Typography>
            </div>
        </Popper>
    );
}

export default CustomErrorSignPopper;
