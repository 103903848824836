import { Card, CardContent, Container, Divider, Link, Typography } from '@mui/material';
import '../css/ComponenteFirmar.css';
import logo from '../img/logo.png';
import UploadFiles from './UploadFiles';

const ComponenteFirmar = () => {
    return (
        <Container maxWidth="lg">
            <Card variant="outlined" className="cardContainer">
                <CardContent>
                    <Typography variant="h4" className='title' gutterBottom>
                        Firmar Archivos
                    </Typography>
                    <Typography variant="body1" paragraph className='bodyFirmar'>
                        Por favor, selecciona uno o varios archivos PDF que desees firmar digitalmente. Una vez que selecciones los archivos, haz clic en el botón "Subir" para iniciar el proceso de firma digital. Después de la firma, podrás descargar los documentos firmados en un archivo ZIP.
                    </Typography>
                    <UploadFiles />
                </CardContent>
                <Divider />
                <CardContent className="cardFooterContent">
                    <Typography variant="body1" paragraph className='bodySecondFirmar'>
                        Si no tiene instalado el Firmador Digital PJM, puede descargarlo haciendo click <Link href="https://signer-update.pjm.gob.ar">aquí</Link>.
                    </Typography>
                    <div className="logoContainer">
                        <img src={logo} alt="Poder Judicial" className='imgFirmar'/>
                    </div>
                </CardContent>
            </Card>
        </Container>
    );
};

export default ComponenteFirmar;
