import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, Button, CircularProgress, IconButton, Typography } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import '../css/UploadFiles.css';
import abrirFirmador from '../functions/firmador.js';
import ComponenteVisualizarDocs from './ComponenteVisualizarDocs.js';
import CustomCongratsSignPopper from './CustomCongratsSignPopper.js';
import CustomErrorSignPopper from './CustomErrorSignPopper.js';
import CustomSnackbar from './CustomSnackbar.js';

const UploadFiles = () => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('error');
    const [showCongratsPopup, setShowCongratsPopup] = useState(false);
    const [showErrorPopper, setShowErrorPopper] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState(null);
    // eslint-disable-next-line
    const [documentNames, setDocumentNames] = useState([]);
    const [previousShortId, setPreviousShortId] = useState();
    const [zipId, setZipId] = useState();
    const [filterBase64, setFilterBase64] = useState();
    const baseUrl = process.env.REACT_APP_BASE;

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        const pdfFiles = files.filter(file => file.type === 'application/pdf');

        const nonPdfFiles = files.filter(file => file.type !== 'application/pdf');
        if (nonPdfFiles.length > 0) {
            setSnackbarMessage('Solo se pueden enviar archivos PDF');
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
        }

        const newSelectedFiles = [...selectedFiles, ...pdfFiles];
        setSelectedFiles(newSelectedFiles);

        const newUploadedFiles = pdfFiles.map(file => ({
            name: file.name,
            url: URL.createObjectURL(file),
        }));
        setDocumentNames(prevUploadedFiles => [...prevUploadedFiles, ...newUploadedFiles]);
    };

    const handlePreviewFile = async (index, event) => {
        const file = documentNames[index];
        const response = await fetch(file.url);
        const blob = await response.blob();
        const reader = new FileReader();
        reader.onload = () => {
            const documentToPreview = {
                name: file.name,
                url: reader.result,
            };
            setSelectedDocument(documentToPreview);
        };
        reader.readAsDataURL(blob);
    };

    const handleDeleteFile = (index) => {
        const newSelectedFiles = [...selectedFiles];
        newSelectedFiles.splice(index, 1);
        setSelectedFiles(newSelectedFiles);
        const newDocumentNames = [...documentNames];
        newDocumentNames.splice(index, 1);
        setDocumentNames(newDocumentNames);
    };

    const handleUpload = async () => {
        setUploading(true);

        const formData = new FormData();
        selectedFiles.forEach(file => {
            formData.append('files', file);
        });

        try {
            const apiUrl = `${baseUrl}${process.env.REACT_APP_UPLOAD_FILES_TO_SIGN_URL}`;
            formData.append('app', process.env.REACT_APP_APP);
            if (previousShortId) {
                formData.append('shortid', previousShortId);
            }
            const response = await axios.post(apiUrl, formData);

            setFilterBase64(response.data.signer_filter);
            setZipId(response.data.shortid);

            if (response.data.files && response.data.files.length === selectedFiles.length) {
                if (response.data.success === true) {
                    const handleMessageFromFirmador = async (message, event) => {
                        if (message.includes("El usuario cancela sin iniciar proceso de firma" || "Proceso de Firma Cancelado")) {
                            const cleanUrl = `${baseUrl}${process.env.REACT_APP_CLEAN}${response.data.signer_filter}`;
                            try {
                                const response = await axios.delete(cleanUrl);
                                setPreviousShortId(response.data.shortid);
                            } catch (error) {
                                console.error('Error al limpiar la ruta:', error);
                            }
                        } else if (message.includes("COMPLETADO:Proceso de firma completado.")) {
                            setSelectedFiles([]);
                            setDocumentNames([]);
                            setShowCongratsPopup(true);
                            setPreviousShortId(null);
                        }
                    };
                    console.log("response.data.stamp", response.data.stamp)
                    console.log("response.data.signer_filter", response.data.signer_filter)
                    abrirFirmador(response.data.signer_filter, response.data.signer_url_api_call, response.data.stamp, handleMessageFromFirmador, handleFirmadorError);
                } else {
                    console.log("Success es falso:", response.data.success);
                }
            } else {
                console.log("Error no tienen la misma cantidad de archivos a los que subí");
            }

            setSnackbarMessage('Archivos enviados exitosamente');
            setSnackbarSeverity('success');
        } catch (error) {
            console.error('Error al enviar archivos:', error);
            setSnackbarMessage('Error al enviar archivos');
            setSnackbarSeverity('error');
        } finally {
            setUploading(false);
            setOpenSnackbar(true);
        }
    };


    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    const handleFirmadorError = () => {
        setShowErrorPopper(true);
    };

    const handleDownloadZip = async () => {
        const apiUrl = `${baseUrl}${process.env.REACT_APP_DOWNLOAD_ZIP}`;
        const apiUrlWithFilter = `${apiUrl}${filterBase64}`;

        try {
            const response = await axios.get(apiUrlWithFilter, { responseType: 'blob' });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${zipId}.zip`);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error al descargar el ZIP:', error);
        }
    };

    const handleCopyLinkManually = () => {
        const linkToCopy = `${baseUrl}${process.env.REACT_APP_DOWNLOAD_ZIP}${filterBase64}`;
        console.log("Enlace para copiar manualmente:", linkToCopy);
    };

    const handleCopyLink = () => {
        if (navigator.clipboard && navigator.clipboard.writeText) {
            const linkToCopy = `${baseUrl}${process.env.REACT_APP_DOWNLOAD_ZIP}${filterBase64}`;
            navigator.clipboard.writeText(linkToCopy).then(() => {
                setSnackbarMessage('Enlace copiado al portapapeles');
                setSnackbarSeverity('success');
                setOpenSnackbar(true);
            }).catch((error) => {
                console.error('Error al copiar el enlace:', error);
                setSnackbarMessage('Error al copiar el enlace');
                setSnackbarSeverity('error');
                setOpenSnackbar(true);
                handleCopyLinkManually();
            });
        } else {
            handleCopyLinkManually();
        }
    };

    return (
        <Box>
            <input
                type="file"
                accept=".pdf"
                multiple
                onChange={handleFileChange}
                className='inputUploadFiles'
                id="file-upload"
            />
            <Box mb={2}>
                <label htmlFor="file-upload">
                    <Button
                        variant="outlined"
                        component="span"
                        startIcon={<CloudUploadIcon />}
                        className='buttonSeleccionarArchivos'
                    >
                        Seleccionar archivos
                    </Button>
                </label>
            </Box>
            <Typography variant="body1" gutterBottom className="typographyFilesSelected">
                {selectedFiles.length > 0 && `${selectedFiles.length} archivos seleccionados`}
            </Typography>
            {selectedFiles.length > 0 && (
                <ul>
                    {selectedFiles.map((file, index) => (
                        <Box key={index} mb={1}>
                            <li className="itemList">
                                {file.name}
                                <IconButton onClick={() => handleDeleteFile(index)} aria-label="delete">
                                    <DeleteIcon />
                                </IconButton>
                                <IconButton onClick={() => handlePreviewFile(index)} aria-label="preview">
                                    <VisibilityIcon />
                                </IconButton>
                            </li>
                        </Box>
                    ))}
                </ul>
            )}
            {selectedDocument && (
                <ComponenteVisualizarDocs document={selectedDocument} open={true} onClose={() => setSelectedDocument(null)} />
            )}

            <Button
                variant="contained"
                onClick={handleUpload}
                disabled={uploading || selectedFiles.length === 0}
                startIcon={<CheckCircleOutlineIcon />}
                style={{
                    backgroundColor: (uploading || selectedFiles.length !== 0) ? "#2e7d32" : undefined
                }}
                className='buttonEnviarYFirmar'
            >
                {uploading ? <CircularProgress size={24} color="inherit" /> : 'Enviar y Firmar'}
            </Button>
            <CustomSnackbar
                open={openSnackbar}
                message={snackbarMessage}
                severity={snackbarSeverity}
                onClose={handleCloseSnackbar}
                className="message"
            />
            <CustomCongratsSignPopper open={showCongratsPopup} handleDownloadZip={handleDownloadZip} handleCopyLink={handleCopyLink} onClose={() => setShowCongratsPopup(false)} />
            <CustomErrorSignPopper open={showErrorPopper} onClose={() => setShowErrorPopper(false)} />
        </Box>
    );
}

export default UploadFiles;
