import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import React from 'react';
import "../css/ComponenteVisualizarDocs.css";
import PdfViewer from './PdfViewer.js';

const ComponenteVisualizarDocs = ({ document, open, onClose }) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle className='titlePdfViewer'>
                {document.name}
                <IconButton aria-label="close" onClick={onClose} sx={{ position: 'absolute', right: 8, top: 8 }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <PdfViewer pdfBase64={document.url} />
            </DialogContent>
        </Dialog>
    );
}

export default ComponenteVisualizarDocs;
