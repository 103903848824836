import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import ComponenteFirmar from './components/ComponenteFirmar';
import ComponenteVerificar from './components/ComponenteVerificar';

const View = () => {
  /*  const history = useHistory();
 
   const handleLogout = () => {
     history.push('/login');
   }; */

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/firmar" />} />
      <Route path="/firmar" element={<ComponenteFirmar />} />
      <Route path="/verificar" element={<ComponenteVerificar />} />
      {/* <Route path="/salir">
        <img onLogout={handleLogout} />
      </Route> */}
    </Routes>
  );
};

export default View;
