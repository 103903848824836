import CloseIcon from '@mui/icons-material/Close';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button, Card, CardContent, IconButton, Popper, Typography } from '@mui/material';
import React from 'react';


const CustomCongratsSignPopper = ({ open, onClose, handleDownloadZip, handleCopyLink }) => {
    return (
        <Popper open={open} placement="auto" transition className="popperUploadFiles">
            {({ TransitionProps }) => (
                <Card className="congrats-popup" {...TransitionProps}>
                    <CardContent>
                        <div className="divContentPopper">
                            <Typography variant="h5" className="tittlePopper" component="div">
                                Felicitaciones, ha firmado correctamente
                            </Typography>
                            <IconButton onClick={onClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <Typography variant="body1" className="popperBody1">
                            Puede descargar un archivo zip para obtener los documentos firmados.
                        </Typography>
                        <Typography variant="body1" className="popperBody1">
                            O puede copiar un enlace para compartir por el medio conveniente.
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleDownloadZip}
                            startIcon={<CloudDownloadIcon />}
                            className='buttonSignUpload'
                        >
                            Descargar Archivos Firmados (Zip)
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleCopyLink}
                            startIcon={<ContentCopyIcon />}
                            className='buttonCopyUpload'
                        >
                            Copiar enlace
                        </Button>
                    </CardContent>
                </Card>
            )}
        </Popper>
    );
}

export default CustomCongratsSignPopper;
